
// *
//     {
        
//         -webkit-box-sizing:border-box;
//         box-sizing:border-box
//     }
// body
//     {
//         padding:0;
//         margin:0

//     }
#notfound
    {
        position:relative;
        height:100vh;
        background-color:#222
    }
#notfound .notfound
    {
        position:absolute;
        left:50%;
        top:50%;
        -webkit-transform:translate(-50%,-50%);
        -ms-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%)
    }
.notfound
    {
        max-width:460px;
        width:100%;
        text-align:center;
        line-height:1.4
    }
.notfound .notfound-404
    {
        height:158px;
        line-height:153px
    }
.notfound .notfound-404 h1
    {
        font-family:josefin sans,sans-serif;
        color:#222;
        font-size:220px;
        letter-spacing:10px;
        margin:0;
        font-weight:700;
        text-shadow:2px 2px 0 #c9c9c9,-2px -2px 0 #c9c9c9
    }
.notfound .notfound-404 h1>span
    {
        text-shadow:2px 2px 0 #fbc31b,-2px -2px 0 #fbc31b,0 0 8px #ff8700
    }
.notfound p
    {
        font-family:josefin sans,sans-serif;
        color:#c9c9c9;
        font-size:16px;
        font-weight:400;
        margin-top:0;
        margin-bottom:15px
    }
.notfound a
    {
        font-family:josefin sans,sans-serif;
        font-size:14px;
        text-decoration:none;
        text-transform:uppercase;
        background:0 0;
        color:#fbc31b;
        border:2px solid #fbc31b;
        display:inline-block;
        padding:10px 25px;
        font-weight:700;
        -webkit-transition:.2s all;
        transition:.2s all
    }
.notfound a:hover
    {
        color:#fff;
        border-color:#fbc31b
    }
    .text-notfound {
        margin-top: 45px;
    }
@media only screen and (max-width:480px)
    {
        .notfound .notfound-404
            {
                height:122px;
                line-height:122px
            }
        .notfound .notfound-404 h1
            {
                font-size:122px
            }
    }